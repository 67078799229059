import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Authentication, { AuthenticationContext } from './Contexts/Authentication';
import EventHandler, { EventContext } from './Contexts/Events';
import Data, { DataContext } from './Contexts/Data';
import ApiRequest from './Components/ApiRequest';
import './index.scss';

const authentication = new Authentication();
const apiRequest = new ApiRequest(authentication);
const eventHandler = new EventHandler();
const dataLayer = new Data(eventHandler, apiRequest);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthenticationContext.Provider value={authentication}>
      <EventContext.Provider value={eventHandler}>
        <DataContext.Provider value={dataLayer}>
          <App />
        </DataContext.Provider>
      </EventContext.Provider>
    </AuthenticationContext.Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();