import { createContext } from "react";
import EventHandler from "../Events";
import ApiRequest, { urlBase64ToUint8Array } from "../../Components/ApiRequest";
import DB from "../DB";
import moment from "moment";

export default class Data {
    eventHandler: EventHandler;
    apiRequest: ApiRequest;
    db: DB;

    constructor(eventHandler: EventHandler, apiRequest: ApiRequest) {
        this.eventHandler = eventHandler;
        this.apiRequest = apiRequest;
        this.db = new DB();

        this.eventHandler.on('network:change', this.networkChange.bind(this));

        this.eventHandler.on('profile:request', this.requestProfile.bind(this));
        this.eventHandler.on('profile:request-remote', this.requestRemoteProfile.bind(this));
        this.eventHandler.on('profile:request-local', this.requestLocalProfile.bind(this));
        this.eventHandler.on('profile:update-local', this.updateLocalProfile.bind(this)); 

        this.eventHandler.on('badges:request', this.requestBadges.bind(this));
        this.eventHandler.on('badges:request-remote', this.requestRemoteBadges.bind(this));
        this.eventHandler.on('badges:request-local', this.requestLocalBadges.bind(this));;
        this.eventHandler.on('badges:update-local', this.updateLocalBadges.bind(this));

        this.eventHandler.on('levels:request', this.requestLevels.bind(this));
        this.eventHandler.on('levels:request-remote', this.requestRemoteLevels.bind(this));
        this.eventHandler.on('levels:request-local', this.requestLocalLevels.bind(this));
        this.eventHandler.on('levels:update-local', this.updateLocalLevels.bind(this));

        this.eventHandler.on('centres:request', this.requestCentres.bind(this));
        this.eventHandler.on('centres:request-remote', this.requestRemoteCentres.bind(this));
        this.eventHandler.on('centres:request-local', this.requestLocalCentres.bind(this));
        this.eventHandler.on('centres:update-local', this.updateLocalCentres.bind(this));

        this.eventHandler.on('criteria:request', this.requestCriteria.bind(this));
        this.eventHandler.on('criteria:request-remote', this.requestRemoteCriteria.bind(this));
        this.eventHandler.on('criteria:request-local', this.requestLocalCriteria.bind(this));
        this.eventHandler.on('criteria:update-local', this.updateLocalCriteria.bind(this));

        this.eventHandler.on('instructors:request', this.requestInstructors.bind(this));
        this.eventHandler.on('instructors:request-remote', this.requestRemoteInstructors.bind(this));
        this.eventHandler.on('instructors:request-local', this.requestLocalInstructors.bind(this));
        this.eventHandler.on('instructors:update-local', this.updateLocalInstructors.bind(this));

        this.eventHandler.on('lessons:request', this.requestLessons.bind(this));
        this.eventHandler.on('lessons:request-remote', this.requestRemoteLessons.bind(this));
        this.eventHandler.on('lessons:request-local', this.requestLocalLessons.bind(this));
        this.eventHandler.on('lessons:update-local', this.updateLocalLessons.bind(this));
        this.eventHandler.on('lesson:request', this.requestLesson.bind(this));
        this.eventHandler.on('lesson:request-remote', this.requestRemoteLesson.bind(this));
        this.eventHandler.on('lesson:request-local', this.requestLocalLesson.bind(this));
        this.eventHandler.on('lesson:update-local', this.updateLocalLesson.bind(this));

        this.eventHandler.on('attendances:request', this.requestLessonAttendances.bind(this));
        this.eventHandler.on('moveLessons:request', this.requestMoveLessons.bind(this));
        this.eventHandler.on('moveLesson', this.requestMoveLesson.bind(this));

        this.eventHandler.on('actions:add', this.addAction.bind(this));
        this.eventHandler.on('actions:sync', this.syncActions.bind(this));

        this.eventHandler.on('logout', this.logout.bind(this));
    }

    getApi() {
        return this.apiRequest;
    }

    async networkChange(online: any) {
        if (online) {
            this.eventHandler.trigger('actions:sync');
        }
    }

    async requestProfile() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('profile:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteProfile() {
        try {
            const body = await this.apiRequest.get('/app/profile');
            this.eventHandler.trigger('profile:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote profile, reverting to local profile.');            
            this.eventHandler.trigger('profile:request-local');
        }
    }

    async requestLocalProfile() {
        const profile = await this.db.get('profile', this.apiRequest.authentication.userId());
        this.eventHandler.trigger('profile:receive', profile, 'local');
    }

    async updateLocalProfile(profile: any, from: any) {
        if (!this.apiRequest.authentication.userId()) {
            this.apiRequest.authentication.setUserId(profile.id);
        }
        await this.db.put('profile', profile);
        this.eventHandler.trigger('profile:receive', profile, from);
    }

    async requestBadges() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('badges:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteBadges() {
        try {
            const body = await this.apiRequest.get('/app/badges');
            this.eventHandler.trigger('badges:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote badges, reverting to local badges.');
            this.eventHandler.trigger('badges:request-local');
        }
    }

    async requestLocalBadges() {
        const badges = await this.db.getAll('badges');
        this.eventHandler.trigger('badges:receive', badges, 'local');
    }

    async updateLocalBadges(badges: any, from: any) {
        await this.db.clear('badges');
        await this.db.bulkAdd('badges', badges);
        this.eventHandler.trigger('badges:receive', badges, from);
    }


    async requestLevels() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('levels:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteLevels() {
        try {
            const body = await this.apiRequest.get('/app/levels');
            this.eventHandler.trigger('levels:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote levels, reverting to local levels.');
            this.eventHandler.trigger('levels:request-local');
        }
    }

    async requestLocalLevels() {
        const levels = await this.db.getAll('levels');
        this.eventHandler.trigger('levels:receive', levels, 'local');
    }

    async updateLocalLevels(levels: any, from: any) {
        await this.db.clear('levels');
        await this.db.bulkAdd('levels', levels);
        this.eventHandler.trigger('levels:receive', levels, from);
    }
    

    async requestCentres() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('centres:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteCentres() {
        try {
            const body = await this.apiRequest.get('/app/centres');
            this.eventHandler.trigger('centres:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote centres, reverting to local centres.');
            this.eventHandler.trigger('centres:request-local');
        }
    }

    async requestLocalCentres() {
        const centres = await this.db.getAll('centres');
        this.eventHandler.trigger('centres:receive', centres, 'local');
    }

    async updateLocalCentres(centres: any, from: any) {
        await this.db.clear('centres');
        await this.db.bulkAdd('centres', centres);
        this.eventHandler.trigger('centres:receive', centres, from);
    }
    

    async requestCriteria() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('criteria:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteCriteria() {
        try {
            const body = await this.apiRequest.get('/app/criteria');
            this.eventHandler.trigger('criteria:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote criteria, reverting to local criteria.');
            this.eventHandler.trigger('criteria:request-local');
        }
    }

    async requestLocalCriteria() {
        const criteria = await this.db.getAll('criteria');
        this.eventHandler.trigger('criteria:receive', criteria, 'local');
    }

    async updateLocalCriteria(criteria: any, from: any) {
        await this.db.clear('criteria');
        await this.db.bulkAdd('criteria', criteria);
        this.eventHandler.trigger('criteria:receive', criteria, from);
    }
    

    async requestInstructors() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('instructors:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteInstructors() {
        try {
            const body = await this.apiRequest.get('/app/instructors');
            this.eventHandler.trigger('instructors:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote instructor, reverting to local instructors.');
            this.eventHandler.trigger('instructors:request-local');
        }
    }

    async requestLocalInstructors() {
        const instructor = await this.db.getAll('instructors');
        this.eventHandler.trigger('instructors:receive', instructor, 'local');
    }

    async updateLocalInstructors(instructors: any, from: any) {
        await this.db.clear('instructors');
        await this.db.bulkAdd('instructors', instructors);
        this.eventHandler.trigger('instructors:receive', instructors, from);
    }


    async requestLessons() {
        this.eventHandler.trigger('lessons:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteLessons() {
        try {
            const body = await this.apiRequest.get('/app/lessons', 10000);
            this.eventHandler.trigger('lessons:update-local', body, 'remote');
        } catch (e: any) {
            if (e.name === 'TimeoutError' || e.name === 'AbortError') {
                this.eventHandler.trigger('error', 'Connection Error', 'There was a problem retrieving the latest lessons, please check your internet connection.');
            }
            console.error('Failed to fetch remote lessons, reverting to local lessons.');
            this.eventHandler.trigger('lessons:request-local');
        }
    }

    async requestLocalLessons() {
        let lessons = await this.db.getAll('lessons');
        const actions = await this.db.getAll('actions', false);
        if (actions.length) {
            // Apply all pending actions.
            lessons = await Promise.all(lessons.map(async (lesson: any, idx: any) => {
                const changes = await actions.filter((action: any) => action.action_type === 'lesson' && Number.parseInt(action.action_id) === Number.parseInt(lesson.id));
                changes.forEach((change: any) => {
                    Object.keys(change.payload).forEach((key: any) => {
                        this.db.getOrSet(lesson, key, change.payload[key]);
                    });
                });
                return lesson;
            }));
        }
        this.eventHandler.trigger('lessons:receive', lessons, 'local');
    }

    async updateLocalLessons(lessons: any, from: any) {
        await this.db.clear('lessons');
        const actions = await this.db.getAll('actions', false);
        if (actions.length) {
            // Apply all pending actions.
            lessons = await Promise.all(lessons.map(async (lesson: any, idx: any) => {
                const changes = await actions.filter((action: any) => action.action_type === 'lesson' && Number.parseInt(action.action_id) === Number.parseInt(lesson.id));
                changes.forEach((change: any) => {
                    Object.keys(change.payload).forEach((key: any) => {
                        this.db.getOrSet(lesson, key, change.payload[key]);
                    });
                });
                return lesson;
            }));
        }
        await this.db.bulkPut('lessons', lessons);
        this.eventHandler.trigger('lessons:receive', lessons, from);
    }

    async requestLessonAttendances(courseId: any, date: any) {
        let attendances = await this.db.getAll('lessons', false, {course_id: courseId.toString(), date});
        const actions = await this.db.getAll('actions', false);
        if (actions.length) {
            // Apply all pending actions.
            attendances = await Promise.all(attendances.map(async (lesson: any, idx: any) => {
                const changes = await actions.filter((action: any) => action.action_type === 'lesson' && Number.parseInt(action.action_id) === Number.parseInt(lesson.id));
                changes.forEach((change: any) => {
                    Object.keys(change.payload).forEach((key: any) => {
                        this.db.getOrSet(lesson, key, change.payload[key]);
                    });
                });
                return lesson;
            }));
        }
        this.eventHandler.trigger('attendances:receive:' + courseId, attendances);
    }

    async requestMoveLessons(courseId: any, lessonId: any, data: any) {
        if (navigator.onLine) {
            try {
                const body = await this.apiRequest.post('/app/move-lessons/', {
                    course_id: courseId,
                    lesson_id: lessonId,
                    instructor_id: ('moveInstructorId' in data ? data.moveInstructorId : false),
                    centre_id: ('moveCentreId' in data ? data.moveCentreId : false),
                    level_id: ('moveLevelId' in data ? data.moveLevelId : false),
                    day: ('moveDay' in data ? data.moveDay : false),
                });
                this.eventHandler.trigger('moveLessons:receive:' + courseId, body);
            } catch (e) {
                console.error('Failed to fetch move lessons, reverting to local lessons.');
                this.eventHandler.trigger('moveLessons:receive:' + courseId, false);
            }
        } else {
            this.eventHandler.trigger('moveLessons:receive:' + courseId, false);
        }
    }

    async requestMoveLesson(attendanceId: any, courseLessonId: any, type: any) {
        if (navigator.onLine) {
            try {
                const body = await this.apiRequest.post('/app/move-lesson/', {
                    course_lesson_attendance_id: attendanceId,
                    course_lesson_id: courseLessonId,
                    type
                });
            } catch (e) {
                console.error('Failed to move student.');
            }
        }
    }

    async requestLesson(lessonId: any) {
        // this.eventHandler.trigger('lesson:request-' + (navigator.onLine ? 'remote' : 'local'), lessonId);
        this.eventHandler.trigger('lesson:request-local', lessonId);
    }

    async requestRemoteLesson(lessonId: any) {
        try {
            const body = await this.apiRequest.get('/app/lessons/' + lessonId);
            this.eventHandler.trigger('lesson:update-local', lessonId, body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote lesson, reverting to local lessons.');
            this.eventHandler.trigger('lesson:request-local', lessonId);
        }
    }

    async requestLocalLesson(lessonId: any) {
        const lesson = await this.db.get('lessons', {id: lessonId.toString()});
        this.eventHandler.trigger('lesson:receive:' + lessonId, lesson);
    }

    async updateLocalLesson(lessonId: any, changes: any, createAction: boolean = false) {
        await this.db.update('lessons', Number.parseInt(lessonId), changes);
        if (createAction) {
            await this.db.add('actions', {type: 'lesson', lessonId, changes});
            this.eventHandler.trigger('actions:sync');
        }
        this.eventHandler.trigger('lesson:request-local', lessonId);
    }

    async addAction(action_type: any, action_id: any, payload: any) {
        await this.db.add('actions', {action_type, action_id, payload, created_at: moment().format('YYYY-MM-DD HH:mm:ss')});
    }

    async syncActions() {
        let has_actions = false;
        if (navigator.onLine) {
            const actions = await this.db.getAll('actions');
            if (actions.length) {
                has_actions = true;
                actions.forEach(async (action: any) => {
                    try {
                        switch (action.action_type) {
                            case 'lesson':
                                await this.apiRequest.post('/app/lesson/' + action.action_id, action.payload);
                                break;
                            default:
                                return true;
                        }
                        this.db.delete('actions', Number.parseInt(action.id));
                    } catch (e) {
                        console.error('Failed to sync action: ' + action.id);
                    }
                });
            }
        }
        if (this.apiRequest.authentication.check()) {
            const me = this;
            setTimeout(function() {
                me.eventHandler.trigger('lessons:request');
                me.eventHandler.trigger('badges:request');
                me.eventHandler.trigger('levels:request');
                me.eventHandler.trigger('centres:request');
                me.eventHandler.trigger('instructors:request');
                me.eventHandler.trigger('criteria:request');
            }, (has_actions ? 1000 : 10));
        }
    }


    async logout() {
        this.apiRequest.authentication.logout();
        await this.db.flush();
        await this.db.init();
    }
}

export const DataContext = createContext(null as any);