import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo-2.png';
import './index.scss';

function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="NotFound">
            <img src={Logo} className="LoginLogo" alt="Swim Excellence" width="250" height="58" />
            <p>Page not found</p>
            <button onClick={() => navigate('/')}>Back</button>
        </div>
    );
}

export default NotFound;
