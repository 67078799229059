import { useContext, useEffect, useRef } from 'react';
import './index.scss';
import { AuthenticationContext } from '../Contexts/Authentication';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { DataContext } from '../Contexts/Data';
import moment from 'moment';

var Buffer = require('buffer/').Buffer;

function Token() {
  const { token } = useParams();
  const authentication = useContext(AuthenticationContext);
  const navigation = useRef(useNavigate());

  useEffect(() => {
    if (token) {
      const authArgs = JSON.parse(Buffer.from(token as string, 'base64').toString('utf-8'));
      if ('token' in authArgs) {
        var expires = moment(authArgs.expires_at);
        authentication.login(authArgs.token, expires.valueOf());
        navigation.current('/');
      }
    }
  }, [token]);

  return (
    <div className="Token">
      <p>Go back to the admin portal.</p>
    </div>
  );
}

export default Token;
