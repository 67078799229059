import './index.scss';
import Logo from '../assets/images/logo-1.png';
import { useEffect, useState } from 'react';

function Install() {
    const [installState, setInstallState] = useState(false as any);

    const beforeInstallPrompt = (e: any) => {
      if (localStorage.getItem('pe-install-dismissed') === null) {
        setInstallState(e);
      }
    }

    const ios = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  
    const installApp = async () => {
      installState.prompt();
      // setInstallState(false);
    }

    useEffect(() => {
      window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
      return () => {
        window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
      }
    }, []);

    return (
        <div className="Install">
          <img src={Logo} className="InstallLogo" alt="Swim Excellence" width="185" height="62" />
            {ios && (
              <div className="InstallPrompt">
                  <p>To install this app, please click on the share button, then Add to Home Screen and finally Add.</p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="48" height="48" fill="#FFFFFF">
                    <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                  </svg>
              </div>
            )}
            {!ios && (
              <>
                <p>To install this app, please click on the button below and follow the prompts.</p>
                <button type="button" onClick={(e) => installApp()}>Install App</button>
              </>
            )}
        </div>
    );
}

export default Install;
